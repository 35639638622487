<template>
  <div v-visibility-change="visibilityChange">
    <div class="resultsPatsnt" v-loading="loadingData">
      <div class="title_resultsPatsnt">
        <div class="title_resultsPatsnt_text">
          {{ $t("message.patient_report") }}
        </div>
        <div>
          <div>
            <el-button
              v-if="lastHistory.is_ended == 0 && !list"
              icon="el-icon-edit"
              @click="documentOpen()"
              type="primary"
              round
              >{{ $t("message.write_conclusions") }}</el-button
            >
            <el-button
              v-if="lastHistory.is_ended == 0 && list"
              icon="el-icon-edit"
              @click="edit(list)"
              type="primary"
              round
              >{{ $t("message.update") }}</el-button
            >
            <el-button
              v-if="list"
              icon="el-icon-view"
              @click="show(list)"
              type="primary"
              round
              >{{ $t("message.show_0") }}</el-button
            >
          </div>
          <el-button
            v-if="lastHistory.is_ended == 0 && list"
            class="d-block"
            type="danger"
            icon="el-icon-delete"
            circle
            @click="destroy(list)"
          >
          </el-button>
        </div>
      </div>
    </div>
    <el-dialog
      :title="$t(`message.choose_template`)"
      :append-to-body="true"
      :visible.sync="outerVisible"
      width="40%"
    >
      <el-row :gutter="20">
        <el-col :span="24">
          <el-input
            clearable
            :placeholder="$t('message.search_template')"
            v-model="filterText"
          ></el-input>
          <el-tree
            class="mt-2 filter-tree"
            :data="data"
            node-key="id"
            :filter-node-method="filterNode"
            ref="tree"
            @node-click="handleNodeClick"
            :expand-on-click-node="false"
            show-checkbox
          >
            <span class="custom-tree-node" slot-scope="{ node, data }">
              <span class="maxWidht ss">
                {{ data.name }}
              </span>
            </span>
          </el-tree>
        </el-col>
      </el-row>

      <div slot="footer" class="dialog-footer">
        <el-button @click="createConclusion()" type="success">
          {{ $t("message.create") }}
        </el-button>
        <el-button @click="dialogClose()"> {{$t('message.close')}} </el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { i18n } from "@/utils/i18n";
import drawer from "@/utils/mixins/drawer";
import { mapGetters, mapActions } from "vuex";

export default {
  mixins: [drawer],
  name: "patientConclusion",
  data() {
    return {
      outerVisible: false,
      form: [],
      drawerUpdate: false,
      drawerShow: false,
      drawer2: false,
      loadingData: false,
      isItemsModelVisible: false,
      isLoadingItems: false,
      selectedModel: null,
      backUrl: process.env.VUE_APP_URL_DOCS,
      reopenUpdate: false,
      reopenShow: false,
      filterText: "",
      excel_data: [],
      service_id: null,
      data: null,
      excel_fields: {},
      drawer: {
        create: {
          name: "create",
          component: "componentCreate",
          status: false,
        },
        update: {
          name: "update",
          component: "componentUpdate",
          status: false,
        },
      },
    };
  },
  computed: {
    ...mapGetters({
      list: "hospitalizationFinalConclusions/list",
      states: "states/list",
      token: "auth/token",
      activeCode: "patients/activeCode",
      lastHistory: "patientHistories/model",
    }),
    getId() {
      return this.$route.params.id;
    },
    actions: function () {
      return ["edit"];
    },
  },
  watch: {
    filterText(val) {
      this.$refs.tree.filter(val);
    },
  },
  async mounted() {
    if (this.lastHistory.id === "") await this.getLastHistory({id: this.getId});
    this.updateList({ id: this.lastHistory.id });
    this.categoryDocumentationList({ relation: true })
      .then((res) => {
        this.data = res.data.category_forms;
      })
      .catch((err) => {
        console.log(err);
      });
  },
  methods: {
    ...mapActions({
      categoryDocumentationList: "categoryDocumentation/index",
      updateList: "hospitalizationFinalConclusions/index",
      delete: "hospitalizationFinalConclusions/destroy",
      getLastHistory: "patientHistories/show",
    }),
    fetchData() {},
    visibilityChange(evt, hidden) {
      if (!hidden) {
        this.loadingData = true;
        this.updateList({ id: this.lastHistory.id })
          .then((Res) => {
            this.loadingData = false;
          })
          .catch((err) => {
            this.loadingData = false;
          });
      }
    },
    refresh() {
      this.refreshData()
        .then((res) => {
          this.filterForm = JSON.parse(JSON.stringify(this.filter));
        })
        .catch((err) => {});
    },
    destroy(model) {
      this.$confirm(i18n.t("message.do_you_want"), i18n.t("message.warning"), {
        confirmButtonText: i18n.t("message.yes"),
        cancelButtonText: i18n.t("message.cancel"),
        type: "warning",
      })
        .then(() => {
          this.$loadingCursor("wait");
          this.delete(model.id)
            .then((res) => {
              this.$notify({
            title: 'Успешно',
            type: "success",
            offset: 130,
            message: res.message
          });
              this.$loadingCursor("default");
            })
            .catch((err) => {
              this.$loadingCursor("default");
              console.log(err);
            });
        })
        .catch(() => {
          this.$message({
            type: "warning",
            message: i18n.t("message.cancel"),
          });
        });
    },
    documentOpen() {
      this.outerVisible = true;
      // this.drawer.create.status = true;
    },
    filterNode(value, data) {
      if (!value) return true;
      return data.name.indexOf(value) !== -1;
    },
    handleNodeClick(node, data, value) {},
    createConclusion() {
      let item = this.$refs.tree.getCheckedNodes();
      let template_id = null;
      for (const i in item) {
        if (item[i]) {
          const element = item[i];
          if (element.children.length == 0 && element.category_id) {
            template_id = element.id;
          }
        }
      }

      this.createModal(template_id);
    },

    async show(model) {
      var link = document.createElement("a");
      link.setAttribute(
        "href",
        this.backUrl +
          "/file_orientation_patient_room_final?fileName=" +
          model.path +
          "&user=" +
          this.token +
          "&status=show" +
          "&patient_history_id=" +
          this.lastHistory.id
      );
      link.setAttribute("target", "_blank");
      link.click();
      return;
    },
    async createModal(template_id) {
      var link = document.createElement("a");
      link.setAttribute(
        "href",
        this.backUrl +
          "/file_orientation_patient_room_final?template_id=" +
          template_id +
          "&user=" +
          this.token +
          "&status=create" +
          "&patient_history_id=" +
          this.lastHistory.id
      );
      link.setAttribute("target", "_blank");
      link.click();
      this.dialogClose();
      return;
    },
    async edit(model) {
      var link = document.createElement("a");
      link.setAttribute(
        "href",
        this.backUrl +
          "/file_orientation_patient_room_final?fileName=" +
          model.path +
          "&user=" +
          this.token +
          "&status=edit" +
          "&patient_history_id=" +
          this.lastHistory.id
      );
      link.setAttribute("target", "_blank");
      link.click();

      return;
    },

    dialogClose() {
      this.$refs.tree.setCheckedNodes([]);
      this.outerVisible = false;
      this.form = {};
      this.model = null;
      this.service_id = null;
    },
    beforeClose(done) {
      this.empty();
      this.refreshData();
      done();
    },
    emptyModel() {
      this.empty();
    },
  },
};
</script>
<style lang="scss" >
.itme_resultsPatsnt {
  padding: 20px;
}
.we10 {
  width: 220px;
  text-align: right;
}
</style>